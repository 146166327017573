import React from 'react';

const ContentRenderer = ({ content }) => {
    const renderContent = (content) => {
        switch (content.type) {
            case 'text':
                return <div className="tut_lang_content-text" dangerouslySetInnerHTML={{ __html: content.value }} />;
            case 'html_editor':
                return (
                    <div className="content-html-outline">
                        <p>HTML EDITOR</p>
                        {/* <textarea
                            readOnly
                            value={content.value}
                            className="content-html-editor"
                        /> */}
                        <pre className="content-html-editor">
                        {content.value}
                        </pre>
                    </div>
                );
            case 'css_editor':
                return (
                    <div className="content-html-outline">
                        <p>CSS EDITOR</p>
                        <textarea
                            readOnly
                            value={content.value}
                            className="content-css-editor"
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="tut_lang_content-renderer">
            {content.map((item, index) => (
                <div key={index} className="tut_lang_content-item">
                    {renderContent(item)}
                </div>
            ))}
        </div>
    );
};

export default ContentRenderer;
