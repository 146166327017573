import React from 'react'
import './Resume2.css'
import { ReactComponent as WebsiteSvg } from '../../assets/svgs/website.svg'
import { ReactComponent as MailSvg } from '../../assets/svgs/email-outlined.svg'
import { ReactComponent as PhoneSvg } from '../../assets/svgs/phone.svg'
import { ReactComponent as LocationSvg } from '../../assets/svgs/location.svg'
import { ReactComponent as LinkedInSvg } from '../../assets/svgs/linkedin-outlined.svg'
import { ReactComponent as GithubSvg } from '../../assets/svgs/github.svg'

const Resume2 = () => {
    const handlePress = () => {
        document.title = 'Amit\'s Resume';
        window.print({ title: "Amit's_resume.pdf" });
    }
    return (
        <div className='resume2--body'>
            <div className='resume2--page print-content' media="print">
                <div className='resume2--header'>
                    <div className='resume2--header-la'>
                        <h1 className='resume2--header-la--name'>Amit Kumar</h1>
                        <p className='resume2--header-la--pos'>Software Engineer</p>
                        <p className='resume2--header-la--desc'>Experienced Full Stack Developer proficient in React.js and Node.js, with
                            soft knowledge in React Native. Skilled in crafting dynamic web and
                            mobile applications, with a focus on exceeding client expectations. Led
                            successful cross-platform mobile app launches and implemented performance
                            optimizations, enhancing user satisfaction.</p>
                    </div>
                    <div className='resume2--header-ra'>
                        <div><a href="mailto:amit115017@gmail.com">amit115017@gmail.com</a> <MailSvg width={18} height={18} stroke={"#305072"} /></div>
                        <div><a href='tel:+91 9852972838' target="_blank" >+91 9852972838</a> <PhoneSvg width={18} height={18} stroke={"#305072"} /></div>
                        <div>New Delhi, India <LocationSvg width={18} height={18} fill={"#305072"} /></div>
                        <div><a href="https://www.devamit.in" target="_blank">devamit.in</a> <WebsiteSvg width={18} height={18} stroke={"#305072"}/></div>
                        <div><a href='https://www.linkedin.com/in/amit2197' target="_blank">Linkedin</a> <LinkedInSvg width={22} height={22} fill={"#305072"}/></div>
                        <div><a href='https://github.com/amit2197' target="_blank" >Github</a> <GithubSvg width={18} height={18} fill={"#305072"}/></div>
                    </div>

                </div>
                <hr style={{ position: 'sticky', left: 0, right: 0, borderWidth: 1, borderColor: "#305072", marginBlock: 12 }} />
                <div className='resume2--info'>
                    <div className='resume2--info-ls'>
                        <section className='resume2--info--experiance'>
                            <h2 className='resume2--info--experiance--title'>WORK EXPERIENCE</h2>
                            <div className='resume2--info--experiance--w'>
                                <p className='resume2--info--experiance--wr'>Senior Software Engineer</p>
                                <p className='resume2--info--experiance--wc'>Phnk Networks India Pvt. Ltd</p>
                                <div className='resume2--info--experiance--wt'>
                                    <p>07/2022 - Present</p>
                                    <p>New Delhi, India</p>
                                </div >
                                <ul className='resume2--info--experiance--wd'>
                                    <li >Proficient in MySQL database design, DynamoDB setup, AWS configuration, and Git version control.</li>
                                    <li>Developed Node.js projects, including APIs with Kafka-based chat functionality.</li>
                                    <li>Worked on integrating payment gateways like Razorpay and Google Play for in-app purchases.</li>
                                    <li>Additionally, I’ve implemented AI avatar generation and chat features.</li>
                                    <li>Implemented real-time messaging systems for enhanced user engagement.</li>
                                    <li>Oversee server-side, Android, and web development projects, handling everything from UI to hosting.</li>
                                    <li>Played a significant role in website development, including design and architecture.</li>
                                </ul>
                            </div>
                            <div className='resume2--info--experiance--w'>
                                <p className='resume2--info--experiance--wr'>Software Engineer</p>
                                <p className='resume2--info--experiance--wc'>Kalpkriti Pvt. Ltd</p>
                                <div className='resume2--info--experiance--wt'>
                                    <p>09/2021 - 07/2022</p>
                                    <p>Bengaluru, India</p>
                                </div >
                                <ul className='resume2--info--experiance--wd'>
                                    <li>Redesigned the company website from scratch, ensuring a robust and efficient online presence.</li>
                                    <li>Developed and maintained REST APIs using Node.js, Express.js, and MongoDB, addressing bugs and implementing new features.</li>
                                    <li>Created automation scripts in Python, enhancing efficiency and resolving issues.</li>
                                    <li>Implemented real-time messaging systems for enhanced user engagement.</li>
                                    <li>Utilizing BigQuery to design queries, schedule updates, and plot graphs based on the data.</li>
                                </ul>
                            </div>

                        </section>

                        <section className='resume2--info--edu'>
                            <h2 className='resume2--info--edu--title'>EDUCATION</h2>
                            <div className='resume2--info--edu--s'>
                                <p className='resume2--info--edu--sb'>B.Tech (CSE)</p>
                                <p className='resume2--info--edu--sc'>Dr. B.C. Roy Engineering College</p>
                                <div className='resume2--info--edu--st'>
                                    <p>07/2015 - 02/2020</p>
                                    <p>Durgapur, India</p>
                                </div >
                            </div>

                        </section>
                    </div>
                    <div className='resume2--info-rs'>
                        <section className='resume2--info--skills'>
                            <h2 className='resume2--info--skills--title'>Skills</h2>
                            <ul className='resume2--info--skills--set'>
                                <li>NodeJs</li>
                                <li>MySQL</li>
                                <li>ReactJs</li>
                                <li>ExpressJs</li>
                                <li>Python3</li>
                                <li>Flask</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>JavaScript</li>
                                <li>AWS</li>
                                <li>GitHub</li>
                                <li>BigQuery</li>
                                <li>Kafka</li>
                                <li>MongoDB</li>
                                <li>DynamoDB</li>
                                <li>PostgreSQL</li>
                            </ul>

                        </section>

                        <section className='resume2--info--eca'>
                            <h2 className='resume2--info--eca--title'>EXTRA CURRICULAR ACTIVITIES</h2>
                            <div className='resume2--info--eca--s'>
                                <p className='resume2--info--eca--sc'>Full Stack Web Developer Nano-degree
                                </p>
                                <p className='resume2--info--eca--sf'>Udacity</p>
                                <div className='resume2--info--eca--st'>
                                    <p>07/2022 - 01/2023</p>
                                    <p>Online</p>
                                </div >
                                <ul className='resume2--info--eca--sd'>
                                    <li>Developed responsive web applications using HTML, CSS, and JavaScript.</li>
                                    <li>Utilized Jinja2 for templating in Flask applications.</li>
                                    <li>Created RESTful APIs using Flask.</li>
                                    <li>Implemented user authentication and authorization.</li>
                                    <li>Designed and managed databases using SQL (PostgreSQL).</li>
                                    <li>Performed data modeling and optimized queries for performance.</li>
                                    <li>Deployed applications to cloud platforms such as AWS and Heroku.</li>
                                    <li>Collaborated with teams using Git and GitHub for version control.</li>
                                    <li><a href="https://www.udacity.com/certificate/e/b8d3d0b6-a796-11e8-83b9-83a4e580fef3" target="_blank" rel="noopener noreferrer">certificate 1</a></li>
                                    <li><a href="https://www.udacity.com/certificate/e/b8d3d0b6-a796-11e8-83b9-83a4e580fef3" target="_blank" rel="noopener noreferrer">certificate 2</a></li>
                                </ul>
                            </div>

                        </section>
                    </div>

                </div>
            </div>

            <button className='resume-download' onClick={() => { handlePress() }}>Print</button>

        </div>
    )
}

export default Resume2