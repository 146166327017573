import React from 'react';
import ContentRenderer from './ContentRenderer';

const Topic = ({ topic }) => {
    return (
        <div className="tut_lang_topic" id={topic.topic}>

            <h3 className="tut_lang_topic-title">{topic.topic}</h3>

            <ContentRenderer content={topic.content} />
        </div>
    );
};

export default Topic;
