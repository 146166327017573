import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useLocation, useParams } from 'react-router-dom';
import Ques from './Ques';
import ContentRenderer from './ContentRenderer';
// import jsonData from '../../data/html.json';

const InterViewQues = () => {
    const location = useLocation();

    console.log({ location })


    const [isOpenSideBar, setIsOpenSideBar] = useState(true)
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedQues, setSelectedQues] = useState(0);
    useEffect(() => {

        const pathParts = location.pathname.split('/');
        const pageName = pathParts[2];
        const jsonFilePath = `../data/Interviewques/${pageName}.json`;
        fetch(jsonFilePath)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                return response.json();
            })
            .then(jsonData => {
                setData(jsonData);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                console.log("error", error)
                setLoading(false);
            });
    }, []);


    const handleSelectQues = (id) => {
        setSelectedQues(id);
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching data: {error.message}</div>;
    }
    return (
        <div className='tut_header_section'>
            <Header />
            <div className='tut_lang_name'>
                <div className='left_asidebar'>

                    <ul style={{ display: isOpenSideBar ? 'block' : 'none', width: isOpenSideBar ? 245 : 0 }}>
                        <h1>HTML table content</h1>
                        {data?.document?.map((ques, index) => (
                            <li key={index.toString()} style={{ backgroundColor: selectedQues === ques.id ? "#cfcfcf" : "#fff" }} onClick={() => { handleSelectQues(ques.id) }}>{ques.question}</li>
                        ))}
                    </ul>
                    <div onClick={() => { setIsOpenSideBar(prevOpenSideBar => !prevOpenSideBar) }} className={isOpenSideBar ? 'openSideBar' : 'closeSideBar'}>

                    </div>
                </div>
                <div className='language_notes'>
                    {data?.document?.map((ques, index) => (
                        <div className="tut_lang_topic" style={{ backgroundColor: selectedQues === ques.id ? "#cfcfcf" : "#fff" }} id={ques.id}>

                            <h3 className="tut_lang_topic-title">{ques.question}</h3>

                            <ContentRenderer content={ques.answer} />
                        </div>

                    ))}
                    {/* <Ques ques={data.document?.[selectedQues]} /> */}
                </div>
            </div>
        </div>
    )
}

export default InterViewQues