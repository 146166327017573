import React, { useState } from 'react'
import './Tutorials.css'
import Header from './Header'
const Tutorials = () => {
  const data = [
    { "id": 1, "category": "Programming Language", "name": "Python" },
    { "id": 2, "category": "Web Development", "name": "HTML" },
    { "id": 3, "category": "Web Development", "name": "CSS" },
    { "id": 4, "category": "Web Development", "name": "NodeJs" },
    { "id": 5, "category": "Web Development", "name": "ReactJs" },
    { "id": 6, "category": "Web Development", "name": "Flask" },
    { "id": 7, "category": "Web Development", "name": "Javascript" },
    { "id": 8, "category": "Web Development", "name": "JQuery" },
    { "id": 9, "category": "DevOps", "name": "Git" },
    { "id": 10, "category": "DevOps", "name": "Aws" },
    { "id": 11, "category": "DevOps", "name": "Docker" },
    { "id": 12, "category": "DevOps", "name": "Ubuntu" },
    { "id": 13, "category": "Databases", "name": "MySQL" },
    { "id": 14, "category": "Databases", "name": "SQLite" },
    { "id": 15, "category": "Databases", "name": "MongoDB" },
    { "id": 16, "category": "Databases", "name": "DynamoDB" },
    { "id": 17, "category": "Databases", "name": "PostgreSQL" },
  ]
  return (
    <section className='tut_section'>
      <Header />
      <div className='tut_lang_Card'>
        <ul>
          {data.map(category => (
            <a href={`/tutorials/${category.name.toLowerCase()}`}>
              <li
                key={category}
                className={""}
              >
                {category.name}
              </li>
            </a>
          ))}
        </ul>
      </div>
    </section>
  )
}
export default Tutorials