import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Logo } from '../../assets/svgs/logo.svg'
import { ReactComponent as Seprator } from '../../assets/svgs/seprator.svg'
import './Header.css'
const Introduction = () => {
  const componentRef = useRef(null);
  const scrollDown = () => {
    if (componentRef.current) {
      const componentHeight = componentRef.current.getBoundingClientRect().height;
      window.scrollBy({ top: componentHeight, behavior: 'smooth' });
    }
  };
  const msgBody = `Hey Amit,

  Just checked out your portfolio at devamit.in - seriously impressed! Your skills seem to align perfectly with our ongoing projects. *Would you be available for a discussion regarding potential collaboration?*
  
  Best regards,`
  const [isScrolled, setIsScrolled] = useState(false)
  const [activeHumburger, setActiveHumburger] = useState(false)
  const toggleMenu = () => {
    setActiveHumburger(!activeHumburger);
    if (!activeHumburger) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setIsScrolled(position > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  return (
    <section className='header' ref={componentRef}>
      <header className={`nav-bar ${isScrolled && "nav-bar--scrolled"}`}>
        <a href="/"><Logo className="nav-bar__logo" fill="#fbfcf6" /></a>
        <div className={`menu-bar ${activeHumburger && "open-hamburger"}`}>
          <div className="hamburger" onClick={() => toggleMenu()}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
        <ul className={`nav-bar__links nav-bar__links-items  ${activeHumburger && "open-nav-bar_links"}`}>
          <a href="#about"><li className="nav-bar__link">About me</li></a>
          <a href="#skills"><li className="nav-bar__link">Skills</li></a>
          <a href="#journey"><li className="nav-bar__link">Journey</li></a>
          <a href={`https://wa.me/+919852972838/?text=${encodeURIComponent(msgBody)}`} target="_blank" rel="noreferrer"><li className="nav-bar__button">HIRE ME</li></a>
        </ul>
      </header>
      {/* <div></div> */}
      <div className="introduction">
        <h1 className="introduction__main">Amit Kumar</h1>
        <h2 className="introduction__submain">Software Engineer</h2>
        <Seprator className="introduction__separator" fill="#fbfcf6" />
        <ul className="introduction__social-media">
          <a href="mailto:work@devamit.in">
            <li>
              <i className="fas fa-solid fa-at introduction__social-media-unit"></i>
            </li>
          </a>
          <a href="https://github.com/Amit2197" target="_blank" rel="noopener noreferrer">
            <li>
              <i className="fas fa-brands fa-github introduction__social-media-unit" aria-hidden="true"></i>
            </li>
          </a>
          <a href="https://www.linkedin.com/in/amit2197/" target="_blank" rel="noopener noreferrer">
            <li>
              <i className="fas fa-brands fa-linkedin introduction__social-media-unit" aria-hidden="true"></i>
            </li>
          </a>
        </ul>
      </div>
      <div className='down_arrow' onClick={scrollDown}>
        <i className="fas fa-angle-down" aria-hidden="true"></i>
      </div>
    </section>
  )
}

export default Introduction