import React, { useEffect, useRef, useState } from 'react';
import './Editor.css';
const options = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Courier New', label: 'Courier New' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Times New Roman', label: 'Times New Roman' },
    { value: 'Verdana', label: 'Verdana' },
    { value: 'Impact', label: 'Impact' },
    { value: 'Comic Sans MS', label: 'Comic Sans MS' },
    { value: 'Tahoma', label: 'Tahoma' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Palatino', label: 'Palatino' },
    { value: 'Garamond', label: 'Garamond' },
    { value: 'Trebuchet MS', label: 'Trebuchet MS' },
    { value: 'Arial Black', label: 'Arial Black' },
    { value: 'Brush Script MT', label: 'Brush Script MT' },
    { value: 'Lucida Sans', label: 'Lucida Sans' },
    { value: 'Franklin Gothic Medium', label: 'Franklin Gothic Medium' },
    { value: 'Gill Sans', label: 'Gill Sans' },
    { value: 'Lucida Console', label: 'Lucida Console' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Consolas', label: 'Consolas' },
    { value: 'Futura', label: 'Futura' },
    { value: 'Century Gothic', label: 'Century Gothic' },
    { value: 'Optima', label: 'Optima' },
    { value: 'Candara', label: 'Candara' },
    { value: 'Baskerville', label: 'Baskerville' },
    { value: 'Big Caslon', label: 'Big Caslon' },
    { value: 'Didot', label: 'Didot' },
    { value: 'Rockwell', label: 'Rockwell' },
    { value: 'Bodoni MT', label: 'Bodoni MT' },
];
const Editor = () => {
    const editorRef = useRef(null);
    const [activeStyles, setActiveStyles] = useState([]);
    useEffect(() => {
        editorRef?.current?.focus();
        const handleSelectionChange = () => {
            const styles = [];
            if (document.queryCommandState('bold')) styles.push('bold');
            if (document.queryCommandState('italic')) styles.push('italic');
            if (document.queryCommandState('underline')) styles.push('underline');
            if (document.queryCommandState('strikethrough')) styles.push('strikethrough');
            if (document.queryCommandState('subscript')) styles.push('subscript');
            if (document.queryCommandState('superscript')) styles.push('superscript');
            if (document.queryCommandState('insertOrderedList')) styles.push('insertOrderedList');
            if (document.queryCommandState('insertUnorderedList')) styles.push('insertUnorderedList');
            setActiveStyles(styles);
        };
        document.addEventListener('selectionchange', handleSelectionChange);
        return () => document.removeEventListener('selectionchange', handleSelectionChange);
    }, []);
    const applyStyle = (command, value = null) => {
        document.execCommand(command, false, value);
        editorRef.current.focus();
    };
    const applyFontFamily = (font) => {
        console.log(font)
        document.execCommand('styleWithCSS', false, true);
        document.execCommand('fontName', false, font);
        editorRef.current.focus();
    };
    const applyHeading = (event) => {
        const heading = event.target.value;
        document.execCommand('formatBlock', false, heading === 'p' ? 'P' : heading);
        editorRef.current.focus();
    };
    const insertLink = () => {
        const url = prompt('Enter the URL');
        if (url) {
            document.execCommand('createLink', false, url);
        }
        editorRef.current.focus();
    };
    const insertImage = () => {
        const url = prompt('Enter the image URL');
        if (url) {
            document.execCommand('insertImage', false, url);
        }
        editorRef.current.focus();
    };
    const isActive = (command) => activeStyles.includes(command);
    return (
        <div className="rich-text-editor">
            <div className="toolbar_container">
                <div className='toolbar'>
                    <div>
                        <select onChange={(e) => applyFontFamily(e.target.value)} title="Font" className='fontFamilyContainer'>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>{option.value}</option>
                            ))}
                        </select>
                        <hr />
                        <select onChange={applyHeading} title="Heading">
                            <option value="p">Paragraph</option>
                            <option value="h1">Heading 1</option>
                            <option value="h2">Heading 2</option>
                            <option value="h3">Heading 3</option>
                            <option value="h4">Heading 4</option>
                            <option value="h5">Heading 5</option>
                            <option value="h6">Heading 6</option>
                        </select>
                        <hr />
                        <button onClick={() => applyStyle('bold')} title="Bold" className={isActive('bold') ? 'active' : ''}><strong>B</strong></button>
                        <button onClick={() => applyStyle('italic')} title="Italic" className={isActive('italic') ? 'active' : ''}><em>I</em></button>
                        <button style={{ textDecoration: "underline" }} onClick={() => applyStyle('underline')} title="Underline" className={isActive('underline') ? 'active' : ''}><u>U</u></button>
                        <hr />
                        <button onClick={() => applyStyle('strikethrough')} title="Strikethrough" className={isActive('strikethrough') ? 'active' : ''}><s>S</s></button>
                        <button onClick={() => applyStyle('subscript')} title="Subscript" className={isActive('subscript') ? 'active' : ''}>X<sub>S</sub></button>
                        <button onClick={() => applyStyle('superscript')} title="Superscript" className={isActive('superscript') ? 'active' : ''}>X<sup>S</sup></button>
                        <hr />
                        <button onClick={() => applyStyle('justifyLeft')} title="Align Left" >
                            <div className='left_align_icon'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                        <button onClick={() => applyStyle('justifyCenter')} title="Align Center">
                            <div className='center_align_icon'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                        <button onClick={() => applyStyle('justifyRight')} title="Align Right">
                            <div className='right_align_icon'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                        <hr />
                        <button onClick={() => applyStyle('justifyLeft')} title="Indent Left" >
                        <div className="gg-format-indent-increase"></div>
                        </button>
                        <button onClick={() => applyStyle('justifyCenter')} title="Indent Center">
                        <div className="gg-format-indent-decrease"></div>
                            
                        </button>
                        <hr />
                        <button onClick={() => applyStyle('insertOrderedList')} title="Numbered List" className={isActive('insertOrderedList') ? 'active' : ''}>
                            <div className="number_list_icon">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                        <button onClick={() => applyStyle('insertUnorderedList')} title="Bullet List" className={isActive('insertUnorderedList') ? 'active' : ''}>
                            <div className="bullet_list_icon">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                        <hr />
                        <button onClick={insertLink} title="Insert Link" ><i class="fa-solid fa-link"></i></button>
                        <button onClick={insertImage} title="Insert Image"><i class="fa-solid fa-images"></i></button>
                        <button onClick={insertImage} title="Insert Image"><i class="fa-solid fa-table-cells"></i></button>
                        <hr />
                        <input
                            type="color"
                            onChange={(e) => applyStyle('foreColor', e.target.value)}
                            title="Text Color"
                        />
                        <input
                            type="color"
                            onChange={(e) => applyStyle('hiliteColor', e.target.value)}
                            title="Background Color"
                        />
                    </div>
                    <div>
                        <button><i class="fa-solid fa-rotate-left"></i></button>
                        <button><i class="fa-solid fa-rotate-right"></i></button>
                        <hr />
                        <button><i class="fa-solid fa-code"></i></button>
                    </div>
                </div>
            </div>
            <div
                ref={editorRef}
                className="editor"
                contentEditable
                suppressContentEditableWarning
                style={{ fontFamily: 'Arial' }}
            ></div>
        </div>
    );
};
export default Editor;
