import React from 'react'
import './Skills.css'
import { ReactComponent as NodejsIcon } from '../../assets/svgs/nodejs.svg'
import { ReactComponent as MySqlIcon } from '../../assets/svgs/mysql.svg'
import { ReactComponent as PythonIcon } from '../../assets/svgs/python.svg'
import { ReactComponent as FlaskIcon } from '../../assets/svgs/flask.svg'
import { ReactComponent as ReactIcon } from '../../assets/svgs/react.svg'
import { ReactComponent as AwsIcon } from '../../assets/svgs/aws.svg'
import { ReactComponent as GitHubIcon } from '../../assets/svgs/github.svg'
import { ReactComponent as Html5Icon } from '../../assets/svgs/html.svg'
import { ReactComponent as Css3Icon } from '../../assets/svgs/css.svg'
import { ReactComponent as JavascriptIcon } from '../../assets/svgs/javascript.svg'
import { ReactComponent as KafkaIcon } from '../../assets/svgs/kafka.svg'
import { ReactComponent as ReactNativeIcon } from '../../assets/svgs/reactnative.svg'
import { ReactComponent as SocketIcon } from '../../assets/svgs/socket.svg'
import { ReactComponent as MongoDBIcon } from '../../assets/svgs/mongodb.svg'
import { ReactComponent as BigQueryIcon } from '../../assets/svgs/bigquery.svg'
import { ReactComponent as DynamoDBIcon } from '../../assets/svgs/dynamodb.svg'
import { ReactComponent as PostgreSQLIcon } from '../../assets/svgs/postgresql.svg'
import Carousel from '../../pages/Slides/Carousel'
const Skills = () => {
    const skillArry = [
        { id: 1, name: "NodeJs", level: 3, icon: NodejsIcon },
        { id: 2, name: "MySql", level: 3, icon: MySqlIcon },
        { id: 3, name: "ReactJs", level: 3, icon: ReactIcon },
        { id: 4, name: "Python3", level: 3, icon: PythonIcon },
        { id: 5, name: "Flask", level: 3, icon: FlaskIcon },
        { id: 6, name: "AWS", level: 3, icon: AwsIcon },
        { id: 7, name: "GitHub", level: 3, icon: GitHubIcon },
        { id: 8, name: "Html5", level: 3, icon: Html5Icon },
        { id: 9, name: "CSS3", level: 3, icon: Css3Icon },
        { id: 10, name: "JavaScript", level: 3, icon: JavascriptIcon },
        { id: 11, name: "Kafka", level: 3, icon: KafkaIcon },
        { id: 12, name: "React Native", level: 3, icon: ReactNativeIcon },
        { id: 13, name: "Socket.IO", level: 3, icon: SocketIcon },
        { id: 14, name: "MongoDB", level: 3, icon: MongoDBIcon },
        { id: 15, name: "BigQuery", level: 3, icon: BigQueryIcon },
        { id: 16, name: "DynamoDB", level: 3, icon: DynamoDBIcon },
        { id: 17, name: "PostgreSQL", level: 3, icon: PostgreSQLIcon },

    ]

    return (
        <section className="skills" id='skills'>
            <h1 className='skills__heading'>My Skill Set</h1>
            {/* <Carousel data={skillArry} /> */}

            <div className="" style={{
                display: "flex", flexDirection: "row", gap: 30,
                flexWrap: "wrap", alignItems: "center", justifyContent: "center"
            }}>
                {skillArry?.map((item, index) => (
                    <div key={index} className="" style={{
                        flex: "0 0 auto", display: "flex", padding: 10,
                        flexDirection: "column",
                        backgroundColor: "#fff",
                        borderRadius: 30,
                        alignItems: "center", justifyContent: "center",
                        minWidth: 160, 
                        maxWidth: 180, 
                        // height: 120,
                        aspectRatio: "16/9",
                        cursor: "pointer",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        transition: "box-shadow 0.3s ease, background-color 0.3s ease"
                    }} >
                        <div></div>
                        <item.icon className="skills_item--svg_icon" style={{cursor: "pointer"}} />
                        <p className='skills_item--heading'>{item.name}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Skills