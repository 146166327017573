import React from 'react';
import Topic from './Topic';

const Heading = ({ heading }) => {
    const clickOnTopic = (topic) => {
        window.location.href = `/tutorials/html/#${topic}`
    }
    return (
        <div className="tut_lang_heading">
            <h2 className="tut_lang_heading-title">{heading?.heading}</h2>
            <div className="topics-list-outline">
                <h3 className="topics-list-title">Topics Covered</h3>
                <div className="topics-list-container">
                    {heading?.table_of_contents.map((topic, index) => (
                        <div key={index} onClick={() => { clickOnTopic(topic.topic.toLowerCase()) }} className="topics-list">
                            {topic.topic}
                        </div>
                    ))}
                </div>
            </div>
            <div className="topic-content">
                {heading?.table_of_contents.map((topic, index) => (
                    <Topic key={index} topic={topic} />
                ))}
            </div>

        </div>
    );
};

export default Heading;
